const modalTriggers = document.querySelectorAll('.js_modal-trigger');

if (modalTriggers) {
    modalTriggers.forEach(modalTrigger => {
        modalTrigger.addEventListener('click', event => {
            const targetElementId = modalTrigger.dataset.target;
            const targetElement = document.querySelector(`#${targetElementId}`);

            targetElement.classList.toggle('modal-open');

            setTimeout(() => {
                targetElement.classList.toggle('animate');
            }, 50)
        })
    });
}
