import axios from "axios";

const changeGroupContextElement = document.querySelector('.change-group-context');

if(changeGroupContextElement) {
    let formData = new FormData();

    changeGroupContextElement.addEventListener('change', (event) => {
        const group = event.target.value;
        const url = event.target.dataset.callbackUrl;

        formData.append('group', group);

        axios.post(url, formData)
            .then(response => {})
            .catch(error => {
                console.error(error)
            });
    });
}