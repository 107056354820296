document.addEventListener("DOMContentLoaded", function() {
    const anchors = document.querySelectorAll('.js_register-flow-anchor');

    if (anchors) {
        window.addEventListener('scroll', function () {
            const anchors = document.querySelectorAll('.js_register-flow-anchor');

            anchors.forEach((anchor, key, anchors) => {
                if ((window.pageYOffset + (window.innerHeight / 2)) <= anchor.offsetTop) {
                    document.querySelector(`#${anchor.dataset.anchor}`).classList.remove('current');
                    document.querySelector(`#${anchor.dataset.anchor}`).classList.add('pending');

                    if (anchors.length === key + 1) {
                        document.querySelector(`#complete`).classList.remove('done');
                    }
                } else {
                    document.querySelector(`#${anchor.dataset.anchor}`).classList.remove('pending');
                    document.querySelector(`#${anchor.dataset.anchor}`).classList.add('current');

                    if (anchors.length === key + 1) {
                        document.querySelector(`#complete`).classList.add('done');
                    }
                }
            })
        });
    }
});

