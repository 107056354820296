import { Dutch } from "flatpickr/dist/l10n/nl.js"

flatpickr.l10ns.default.firstDayOfWeek = 1;
flatpickr.l10ns.default.rangeSeparator = ' - ';

flatpickr(".flatpickr", {
    dateFormat: "d/m/Y",
    locale: Dutch
});

flatpickr(".flatpickr-range", {
    mode: "range",
    minDate: "today",
    dateFormat: "d/m/Y",
});

flatpickr(".flatpickr-inline", {
    mode: "range",
    minDate: "today",
    dateFormat: "d/m/Y",
    inline: true,
});

const twoMonthRange = document.querySelector('.flatpickr-two-month-range');

if(twoMonthRange) {
    const currentParams = new URLSearchParams(window.location.search);
    const cm = parseInt(currentParams.get('cm'));

    let twoMonthRangeCalendar = flatpickr(".flatpickr-two-month-range", {
        inline: true,
        showMonths: 2,
        currentMonth: cm ? cm : 0,
        minDate: `${ new Date().getFullYear() }-01-01`,
        maxDate: `${ new Date().getFullYear() + 5 }-12-31`,
        mode: "range",
        onMonthChange: (selectedDates, dateStr, instance) => {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('cm', instance.currentMonth);
            const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
            window.history.pushState({ path: newurl }, '', newurl);
        }
    });

    if (cm) {
        twoMonthRangeCalendar.changeMonth(cm);
    }
}

flatpickr(".timepickr", {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true,
    inline: true,
    defaultDate: "12:00"
});

const selectElements = document.querySelectorAll('.select-element');

if (typeof TomSelect !== 'undefined') {
    selectElements.forEach(selectElement => {
        new TomSelect(selectElement,{
            create: true,
            allowEmptyOption: true
        });
    })
}

