document.addEventListener("DOMContentLoaded", function() {
    const countDownElement = document.querySelector('.js_count-down');

    if (countDownElement) {
        let timeleft = 10;
        const timer = setInterval(function(){
            if(timeleft <= 0){
                clearInterval(timer);
                countDownElement.innerHTML = 0;
                window.location.replace(countDownElement.dataset.redirect);
            } else {
                countDownElement.innerHTML = timeleft;
            }
            timeleft -= 1;
        }, 1500);
    }
});