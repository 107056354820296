const textLimiters = document.querySelectorAll('.js_text-counter');

if (textLimiters) {
    textLimiters.forEach(textLimiter => {
        textLimiter.parentElement.classList.add('text-counter');
        textLimiter.parentElement.dataset.counter = `${textLimiter.value.length ? textLimiter.value.length : 0}/${textLimiter.maxLength}`;

        textLimiter.addEventListener('input', event => {
            textLimiter.parentElement.dataset.counter = `${event.target.value.length}/${textLimiter.maxLength}`;
        })
    });
}