if (window.TRANSLATION_JSON) {
   fetch(window.TRANSLATION_JSON)
       .then(response => response.json())
       .then(data => localStorage.setItem('translations', JSON.stringify(data)))
       .catch(error => console.log(error));
}

trans = (translationString) => {
   const translationArray = translationString.split('.');
   let currentKey = '';

   translationArray.forEach((key) => {
      currentKey = !currentKey ? JSON.parse(localStorage.getItem('translations'))[key] : currentKey[key]
   })

   return currentKey ?? translationString;
}
