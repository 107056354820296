const togglePasswordEl = document.getElementById('js-toggle-password');

if (togglePasswordEl) {
  togglePasswordEl.addEventListener('click', (event) => {
    let textEl = document.getElementById('js-toggle-password-text');
    let iconEl = document.getElementById('js-toggle-password-icon');

    const textShow = textEl.dataset.textShow;
    const textHide = textEl.dataset.textHide;

    textEl.innerText = textEl.innerText.trim() === textShow.trim() ? textHide : textShow;

    let svgEls = iconEl.getElementsByClassName('toggle-password-icon');
    Array.from(svgEls).forEach(svgEl => {
      svgEl.classList.toggle('hidden')
    });

    var passwordEl = document.getElementById('passwordEl');
    passwordEl.type = passwordEl.type === 'password' ? 'text' : 'password';
  });
}