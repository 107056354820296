const headerElement = document.querySelector('header');

if(headerElement) {
    window.onscroll = function (e) {
        if (window.scrollY === 0) {
            headerElement.classList.add('header-bg')
        } else {
            headerElement.classList.remove('header-bg')
        }
    }
}
