const groupSelectElement = document.querySelector('.group-search-selector');
if(groupSelectElement) {
    new TomSelect(groupSelectElement, {
        valueField: 'id',
        labelField: ['name', 'postal_code'],
        searchField: ['name', 'postal_code'],
        maxItems: 1,
        // fetch remote data
        load: function(query, callback) {
            var url = `${groupSelectElement.dataset.callbackUrl}?search=` + encodeURIComponent(query);
            fetch(url)
                .then(response => response.json())
                .then(json => {
                    callback(json);
                }).catch(()=>{
                callback();
            });
        },
        render: {
            option: function(item, escape) {
                return `<div class="py-2 d-flex">
							${ item.name } (${ item.postal_code })
						</div>`;
            },
            item: function(item, escape) {
                return `<div class="py-2 d-flex" x-ref="groupName">
							${ item.name } (${ item.postal_code })
						</div>`;
            },
            no_results: function(data,escape){
                return `<div class="no-more-results">${trans('group.group_not_found')}</div>`;
            }
        },
    });
}
